<template>
  <div class="kifu-info bg-bgcontainer">
    <base-header
      v-if="$device.isMobile"
      :title="$t('棋譜資訊')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="false"
      @on-click-left="$emit('close')"
    ></base-header>
    <b-list-group>
      <b-list-group-item
        v-if="!$device.isMobile"
        class="title bg-bgcontainer px-3 text-18 d-flex justify-content-between align-items-center"
      >
        <span>{{ $t('棋譜資訊') }}</span
        ><span class="icon-X" @click.stop="$emit('close')"></span>
      </b-list-group-item>
      <b-list-group-item class="date bg-bgcontainer px-3">
        {{ $t('日期') }} ：{{ date }}
      </b-list-group-item>
      <b-list-group-item
        v-if="gameModeLabel"
        class="gameModeLabel bg-bgcontainer px-3"
      >
        {{ $t('來源') }} ：{{ gameModeLabel }}
      </b-list-group-item>
      <b-list-group-item
        v-if="competition"
        class="gameModeLabel bg-bgcontainer px-3"
      >
        {{ $t('比賽') }} ：{{ competition }}
      </b-list-group-item>
      <b-list-group-item class="black bg-bgcontainer px-3">
        {{ $t('黑方') }} ：{{ black }}
      </b-list-group-item>
      <b-list-group-item class="white bg-bgcontainer px-3"
        >{{ $t('白方') }} ：{{ white }}
      </b-list-group-item>
      <b-list-group-item v-if="step !== -1" class="step bg-bgcontainer px-3"
        >{{ $t('手數') }} ：{{ step }}{{ $t('手') }}
      </b-list-group-item>
      <b-list-group-item class="result bg-bgcontainer px-3"
        >{{ $t('結果') }} ：{{ result }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';

export default {
  components: {
    BaseHeader,
  },
  props: {
    date: {
      type: String,
      default: '',
    },
    gameModeLabel: {
      type: String,
      default: '',
    },
    competition: {
      type: String,
      default: '',
    },
    black: {
      type: String,
      default: '',
    },
    white: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      default: -1,
    },
    result: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    kifu() {
      return this.$store.state.aiGame.kifu;
    },
  },
};
</script>

<style lang="scss" scoped>
.kifu-info {
  width: 100%;
  height: 100%;
  overflow: overlay;
  @media screen and (min-width: 768px) {
    height: auto;
    width: 300px;
    border-radius: 10px;
  }
  .list-group {
    border-radius: 0;
    .title {
      @media screen and (min-width: 768px) {
        font-weight: 700;
        padding: 16px;
        color: $font-grayscale-1;
        .icon-X {
          font-size: 32px;
          padding: 0;
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
  .list-group-item {
    border-left: 0;
    border-right: 0;
    @media screen and (min-width: 768px) {
      line-height: 28px;
    }
  }
}
</style>
