const excellentHandType2commentMapping = {
  general: '這手棋下得真好！',
  'ladder atari': '這個【征子】下得很好，對方逃不走了！',
  'cut atari': '這是厲害的【斷吃】！對手的活路被截斷了！',
  'double atari': '這是漂亮的【雙叫吃】，可以吃到對方的棋子！',
  'hug atari': '這是厲害的【抱吃】！對方逃不走了！',
  'dead atari': '這手【叫吃】很重要，對方逃不走了！',
  'door atari': '這是厲害的【關門吃】，對方逃不走了！',
  'connect and die': '這是厲害的【接不歸】，對方救不回去了！',
  'throw in': '這手【撲吃】下得很好，對方吃掉以後可以再吃回來！',
  'avoid good atari': '這手棋是有效的【長氣】，讓自己的氣增加，攻殺容易獲勝！',
  run: '時機完美的【逃走】，讓對方吃不到！',
  'necessary eat': '這是【要子】，吃得好！',
  'good link': '這手【連接】下得好，讓棋子變強了！',
  'good cut': '這手【切斷】很有效！',
  'bamboo joint (advanced)': '這個【雙】很棒',
  'peep (advanced)': '這是個很厲害的【刺】，瞄準了對方的斷點！',
  'nobi (advanced)': '這個【長】很棒',
  'collide (advanced)': '這個【撞】很棒',
  'bend (advanced)': '這個【彎】很棒',
  'strectch (advanced)': '這個【並】很棒',
  'stand (advanced)': '這個【立】很棒',
  'push through (advanced)': '這個【衝】很棒',
  'block (advanced)': '這手【擋】很有效，對方的棋子過不來了！',
  'hane (advanced)': '這個【扳】很棒',
  'diagonal move (advanced)': '這個【尖】很棒',
  'tiger (advanced)': '這個【虎】很棒',
  'jump (advanced)': '這個【跳】很棒',
  'knight move (advanced)': '這個【飛】很棒',
  'cut (advanced)': '【切斷】這裡比較厲害！',
  'link (advanced)': '這個【連接】很重要唷~',
  'good ignore': '',
};
const questionHandType2commentMapping = {
  general: '這手棋是問題手',
  'in tiger mouth': '這裡是【虎口】，下了會被吃掉',
  'bad run': '這裡逃不走了！',
  'failed ladder': '這時候不應該叫吃',
  suicide: '下這裡會送對手吃唷！',
  'miss importance': '現在棋盤上有更重要的地方，應該【手拔】',
  'need to ignore': '現在棋盤上有更重要的地方，應該【手拔】',
  'do not nobi': '這手【長】是問題手',
  'do not bend': '這手【彎】是問題手',
  'do not stand': '這手【立】是問題手',
  'do not strectch': '這手【並】是問題手',
  'do not squeeze': '這手【擠】是問題手',
  'do not collide': '這手【撞】是問題手',
  'do not atari': '這手【叫吃】是問題手',
  'do not cut': '這手【斷】是問題手',
  'do not link': '這手【連】是問題手',
  'do not push through': '這手【沖】是問題手',
  'do not block': '這手【擋】是問題手',
  'do not hane': '這手【扳】是問題手',
  'do not diagonal move': '這手【尖】是問題手',
  'do not tiger': '這手【虎】是問題手',
  'do not jump': '這手【跳】是問題手',
  'do not knight move': '這手【飛】是問題手',
  'do not peep': '這手【刺】是問題手',
  'do not run': '這手【逃】是問題手',
  'do not eat': '這手【提吃】是問題手',
  'do not bamboo joint': '這手【雙】是問題手',
};
const suggestType2commentMapping = {
  general: '下在這裡會更厲害喔！',
  'miss double atari': '這裡有個【雙叫吃】可以吃到對方的棋子！',
  'miss ladder atari': '下在這裡就可以利用【征子】吃掉對方唷！',
  'miss cut atari': '應該下在這裡【斷吃】！截斷對方的活路！',
  'miss door atari': '應該下這裡【關門吃】！就可以把對方吃掉！',
  'miss hug atari': '這裡可以使用【抱吃】！就可以把對方吃掉',
  'miss dead atari': '應該從這邊【叫吃】，就可以把對方吃掉！',
  'miss connect and die': '這裡有個【接不歸】，可以把對方吃掉！',
  'miss throw in': '這裡可以使用【撲吃】！對方吃掉以後再吃回來！',
  'need to run': '這裡剩一氣！快逃跑！',
  'need to eat': '這是【要子】，應該趕快吃掉！',
  'need to avoid good atari': '要下在這裡【長氣】！',
  'miss good link': '這裡要快點【連接】，被切斷的話會危險的！',
  'miss good cut': '應該【切斷】對方，再看要攻擊哪一塊？',
  'miss run and link': '下這裡可以一邊逃走一邊連接',
  'need to ignore': '下這個要點！',
  'need to nobi (advanced)': '【長】在這裡會更好喔！',
  'need to bend (advanced)': '【彎】在這裡會更好喔！',
  'need to strectch (advanced)': '【並】在這裡會更好喔！',
  'need to stand (advanced)': '【立】在這裡會更好喔！',
  'need to squeeze (advanced)': '【擠】在這裡會更好喔！',
  'need to collide (advanced)': '【撞】在這裡會更好喔！',
  'need to atari (advanced)': '這時候下在這邊【叫吃】會更好喔！',
  'need to cut (advanced)': '【切斷】這裡比較厲害！',
  'need to link (advanced)': '這時候【連接】這裡會更好喔！',
  'need to push through (advanced)': '這時候在這邊【沖】會更好喔！',
  'need to block (advanced)': '這時候在這邊【擋】會更好喔！',
  'need to hane (advanced)': '這時候在這邊【扳】會更好喔！',
  'need to diagonal move (advanced)': '這時候在這邊【尖】會更好喔！',
  'need to tiger (advanced)': '這時候在這邊【虎】會更好喔！',
  'need to jump (advanced)': '這時候在這邊【跳】會更好喔！',
  'need to knight move (advanced)': '這時候在這邊【飛】會更好喔！',
  'need to peep (advanced)': '這時候在這邊【刺】會更好喔！',
  'need to run (advanced)': '應該下在這裡逃跑！',
  'need to eat (advanced)': '這時候下在這裡【提吃】會更好喔！',
  'need to bamboo joint (advanced)': '這時候在這邊【雙】會更好喔！',
  'need to make life': '現在應該【做眼】，來做活這塊棋！',
  'need to kill': '現在應該【破眼】，來攻擊這塊棋！',
};

const suggestionStrategyType2commentMapping = {
  battle: '因為這是戰鬥的要點！',
  attack: '可以有效攻擊對方的棋子！',
  'increase territory': '可以增加地盤！',
  'invade territory': '可以破壞對方地盤！',
};

/**
 * 好棋對照評語
 * @param {String} handType 傳來的類型
 * @returns {String} 回傳評語
 */
function processExcellentHand(handType) {
  return excellentHandType2commentMapping[handType];
}

/**
 * 問題手對照評語
 * @param {String} handType 傳來的類型
 * @returns {String} 回傳評語
 */
function processQuestionHand(handType) {
  return questionHandType2commentMapping[handType];
}

/**
 * 正解對照評語
 * @param {String} suggestionType 傳來的類型
 * @returns {String} 回傳評語
 */
function processSuggest(suggestionType) {
  return suggestType2commentMapping[suggestionType];
}

/**
 * 正解手進階對照評語
 * @param {String} suggestionStrategyType 傳來的類型
 * @returns {String} 回傳評語
 */
function processSuggestionStrategy(suggestionStrategyType) {
  return suggestionStrategyType2commentMapping[suggestionStrategyType];
}

export default {
  processExcellentHand,
  processQuestionHand,
  processSuggest,
  processSuggestionStrategy,
};
