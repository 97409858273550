<template>
  <div class="kifu-preview bg-bgsection h-100">
    <base-header
      :title="$t('棋譜')"
      :is-title-bold="true"
      :left-arrow="$device.isMobile"
      :left-text="!$device.isMobile ? $t('返回上一頁') : ''"
      :right-icon="$device.isMobile ? 'Elert-info' : 'Setting'"
      :back-on-click-left="false"
      @on-click-right="onBaseHeaderRightClick"
      @on-click-left="onClickGoBack"
    ></base-header>
    <div
      v-if="kifu?.boardSize"
      class="content px-3 p-md-4 pt-3 pd-md-0 d-flex flex-column flex-md-row justify-content-md-center"
    >
      <div v-if="isVariationShow" class="mb-2 mb-md-0">
        <Chessboard
          ref="chessboard"
          mode="board"
          :board-size="kifu.boardSize"
          :sgf="kifu.sgf"
          :game-type="kifu.gameMode || 'game'"
          toolbar="checkVariation"
          :has-border="true"
          :variation="variation"
          :hide-tool-bar="!$device.isMobile"
          @cap-count-change="onCapCountChange"
          @step-type-change="onStepTypeChange"
          @current-step-change="onStepChange"
          @max-step-change="onMaxStepChange"
          @is-test-play="onTestPlayChange"
          @board-grid-change="onBoardGridChange"
        ></Chessboard>
      </div>
      <div v-else class="mb-2 mb-md-0">
        <Chessboard
          ref="chessboard"
          mode="board"
          :board-size="kifu.boardSize"
          :sgf="kifu.sgf"
          :game-type="kifu.gameMode"
          toolbar="checkKifu"
          :has-border="false"
          :hide-tool-bar="!$device.isMobile"
          :kifu-review-game="kifuReviewGame"
          :has-influence="!$isJungo"
          :has-test-play="!$isJungo"
          @cap-count-change="onCapCountChange"
          @step-type-change="onStepTypeChange"
          @current-step-change="onStepChange"
          @max-step-change="onMaxStepChange"
          @is-test-play="onTestPlayChange"
          @board-grid-change="onBoardGridChange"
        ></Chessboard>
      </div>
      <div
        v-if="!$device.isMobile"
        class="wrap-operate-panel bg-bgcontainer ml-md-4"
      >
        <div
          class="operate-panel d-md-flex flex-md-column justify-content-md-between"
        >
          <div>
            <b-row class="wrap-user mb-2 mb-md-0 px-md-2">
              <b-col
                cols="6"
                md="12"
                class="p-0 pr-2 pr-md-0 card-user my-md-2"
              >
                <b-card
                  bg-variant="blackChess"
                  text-variant="white"
                  border-variant="white"
                  class="position-relative"
                >
                  <div class="d-flex flex-nowrap align-items-center">
                    <b-img
                      class="avatar"
                      rounded="circle"
                      :srcset="blackPlayerAvatar"
                      alt="avatar"
                    ></b-img>
                    <div class="wrap-user-details d-flex flex-column">
                      <p class="user-name m-0">
                        {{ blackPlayer.nickName }}
                      </p>
                      <p class="user-detail m-0">
                        {{ $t('提子') }}：
                        {{
                          $isJungo ? boardCount.blackBoardCount : capCount.black
                        }}
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="6" md="12" class="p-0 card-user">
                <b-card
                  border-variant="white"
                  class="card-shadow position-relative"
                >
                  <div class="d-flex flex-nowrap align-items-center">
                    <b-img
                      class="avatar"
                      rounded="circle"
                      :srcset="whitePlayerAvatar"
                      alt="avatar"
                    ></b-img>
                    <div class="wrap-user-details d-flex flex-column">
                      <p class="user-name m-0">
                        {{ whitePlayer.nickName }}
                      </p>
                      <p class="user-detail m-0">
                        {{ $t('提子') }}：
                        {{
                          $isJungo ? boardCount.whiteBoardCount : capCount.white
                        }}
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <div class="position-relative">
            <div class="remark-wrapper px-md-2">
              <transition name="fade">
                <div
                  v-if="hasQuestionHand && !isSuggestHandShow && !isTestPlay"
                  class="remark text-center d-flex flex-column justify-content-center align-items-center p-3"
                >
                  <p
                    class="mb-1 d-flex align-items-center justify-content-center text-negative"
                  >
                    <span class="icon-X mr-1"></span>{{ $t('問題手') }}
                  </p>
                  <p class="question-comment">{{ questionComment }}</p>
                  <b-button
                    variant="primary"
                    class="check-question-hand m-0 d-flex align-items-center"
                    size="sm"
                    @click="suggestHandShow"
                  >
                    <span class="icon-Arrow-left-right mr-1 text-16"></span
                    >{{ $t('查看正解') }}</b-button
                  >
                </div>
                <div
                  v-else-if="isSuggestHandShow && !isTestPlay"
                  class="remark text-center p-3"
                >
                  <p
                    class="mb-1 d-flex align-items-center justify-content-center text-primary"
                  >
                    <span class="icon-Star mr-1"></span>{{ $t('正解') }}
                  </p>
                  <p class="question-comment">{{ suggestComment }}</p>
                  <b-button
                    :variant="$isJungo ? 'cyan-blue' : 'negative'"
                    class="check-question-hand mx-auto my-0 d-flex align-items-center"
                    :class="{'text-white': $isJungo}"
                    size="sm"
                    @click="suggestHandHide"
                  >
                    <span class="icon-Arrow-left-right mr-1 text-16"></span>
                    {{ $t('返回') }}
                  </b-button>
                </div>
                <div
                  v-else-if="hasExcellentHand && !isTestPlay"
                  class="remark text-center p-md-3"
                >
                  <p
                    class="mb-1 d-flex align-items-center justify-content-center text-positive"
                  >
                    <span class="icon-like mr-1"></span>{{ $t('好棋') }}
                  </p>
                  <p class="m-0">{{ excellentComment }}</p>
                </div>
              </transition>
            </div>
            <ChessboardToolbar
              v-if="!$device.isMobile"
              :mode="chessboardToolbarMode"
              :step-type="stepType"
              :current-step="currentStep"
              :max-step="maxStep"
              :kifu-review-game="kifuReviewGame"
              :has-influence="!$isJungo"
              :has-test-play="!$isJungo"
              @item-click="onToolbarClick"
              @step-change="goto"
            ></ChessboardToolbar>
            <div
              v-if="kifu.step >= 20 && !kifu.isReviewed && !$isJungo"
              class="btn-review-game px-md-2 pb-md-2"
            >
              <b-button
                size="md"
                class="w-100 text-white"
                :variant="$isJungo ? 'primary' : 'infoAndLink'"
                @click="reviewGame"
              >
                {{ $t('我要覆盤') }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          $device.isMobile && kifu.step >= 20 && !kifu.isReviewed && !$isJungo
        "
        class="btn-review-game"
      >
        <b-button
          size="md"
          class="w-100 text-white"
          :variant="$isJungo ? 'accent-2' : 'infoAndLink'"
          @click="reviewGame"
        >
          {{ $t('我要覆盤') }}
        </b-button>
      </div>
      <div v-if="$device.isMobile" class="remark-wrapper">
        <div
          v-if="hasQuestionHand && !isSuggestHandShow && !isTestPlay"
          class="remark text-center p-3 mb-3"
        >
          <p
            class="mb-1 d-flex align-items-center justify-content-center text-negative"
          >
            <span class="icon-X mr-1"></span>{{ $t('問題手') }}
          </p>
          <p class="question-comment">{{ questionComment }}</p>
          <b-button
            variant="primary"
            class="check-question-hand mx-auto my-0 d-flex align-items-center"
            size="sm"
            @click="suggestHandShow"
          >
            <span class="icon-Arrow-left-right mr-1 text-16"></span>
            {{ $t('查看正解') }}
          </b-button>
        </div>
        <div
          v-else-if="isSuggestHandShow && !isTestPlay"
          class="remark text-center p-3 mb-3"
        >
          <p
            class="mb-1 d-flex align-items-center justify-content-center text-primary"
          >
            <span class="icon-Star mr-1"></span>{{ $t('正解') }}
          </p>
          <p class="question-comment">{{ suggestComment }}</p>
          <b-button
            :variant="$isJungo ? 'primary' : 'negative'"
            class="check-question-hand mx-auto my-0 d-flex align-items-center"
            size="sm"
            @click="suggestHandHide"
          >
            <span class="icon-Arrow-left-right mr-1 text-16"></span>
            {{ $t('返回') }}
          </b-button>
        </div>
        <div
          v-else-if="hasExcellentHand && !isTestPlay"
          class="remark text-center p-3 mb-3"
        >
          <p
            class="mb-1 d-flex align-items-center justify-content-center text-positive"
          >
            <span class="icon-like mr-1"></span>{{ $t('好棋') }}
          </p>
          <p class="m-0">{{ excellentComment }}</p>
        </div>
      </div>
    </div>
    <template v-if="$device.isMobile">
      <transition name="slide-left">
        <KifuInfo
          v-if="isInfoShow"
          :black="kifuData.black"
          :white="kifuData.white"
          :result="kifuData.result"
          :step="kifuData.step"
          :game-mode-label="kifuData.gameModeLabel"
          :date="kifuData.date"
          :competition="kifuData.competition"
          class="kifu-info"
          @close="closeInfo"
        ></KifuInfo>
      </transition>
    </template>
    <modal-wrapper
      v-else-if="isInfoShow && !$device.isMobile"
      :is-modal-wrapper-show="isInfoShow"
      :is-show-transition="true"
      :click-to-close="true"
      @close="closeInfo"
    >
      <KifuInfo
        v-if="isInfoShow"
        :black="kifuData.black"
        :white="kifuData.white"
        :result="kifuData.result"
        :step="kifuData.step"
        :game-mode-label="kifuData.gameModeLabel"
        :date="kifuData.date"
        :competition="kifuData.competition"
        class="kifu-info"
        @close="closeInfo"
        @click.native.stop=""
      ></KifuInfo>
    </modal-wrapper>
    <modal-wrapper
      v-if="isSettingShow && !$device.isMobile"
      :is-modal-wrapper-show="isSettingShow"
      :is-show-transition="true"
      :click-to-close="true"
      @close="isSettingShow = false"
    >
      <Setting
        v-if="isSettingShow"
        @close="isSettingShow = false"
        @click.native.stop=""
      ></Setting>
    </modal-wrapper>
    <review-game-loading
      v-if="isReviewGameLoading"
      :content="ReviewGameLoadingContent"
    ></review-game-loading>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import Chessboard from '@/components/Chessboard/Chessboard.vue';
import ChessboardToolbar from '@/components/Chessboard/ChessboardToolbar.vue';
import KifuInfo from '@/views/Kifu/KifuInfo.vue';
import reviewGameComment from '@/lib/kifu/reviewGameComment.js';
import BackEvent from '@/lib/base/backEvent.js';
import aiLevelMapping from '@/lib/base/aiLevel.js';
import aiCharacterMapping from '@/json/aiCharacter.json';
import {getSgfOnStep, appendSgf} from 'goer-utils/function/sgf';
import {boardToNumber} from 'goer-utils/function/parser';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import Setting from '@/views/Personal/RoomSetting.vue';
import aiGameService from '@/services/aiGame';
import ReviewGameLoading from '@/components/Base/ReviewGameLoading.vue';
import kifuInfo from '@/lib/kifu/kifuInfo';
import filters from '@/lib/base/filters.js';
import _ from 'lodash';

export default {
  components: {
    BaseHeader,
    Chessboard,
    KifuInfo,
    ChessboardToolbar,
    ModalWrapper,
    Setting,
    ReviewGameLoading,
  },
  data() {
    return {
      isInfoShow: false,
      isSettingShow: false,
      currentStep: null,
      excellentComment: '',
      questionComment: '',
      suggestComment: '',
      getSgfOnStep,
      variation: null,
      variationSgf: null,
      capCount: {
        black: 0,
        white: 0,
      },
      boardCount: {
        blackBoardCount: 0,
        whiteBoardCount: 0,
      },
      stepType: 'normal',
      maxStep: 0,
      isReviewed: null,
      isSuggestHandShow: false,
      ReviewGameLoadingContent: '覆盤中',
      hasQuestionHand: false,
      hasExcellentHand: false,
      isTestPlay: false,
    };
  },
  computed: {
    kifu() {
      return this.$store.state.aiGame.kifu;
    },
    createdTime() {
      return this.$route.params.createdTime;
    },
    isGameOverReivewed() {
      return this.$route.query.isGameOverReivewed;
    },
    kifuReviewGame() {
      return this.isReviewed || this.kifu?.isReviewed
        ? this.$store.state.aiGame.kifuReviewGame
        : null;
    },
    isVariationShow() {
      return this.variation != null;
    },
    chessboardToolbarMode() {
      if (this.isTestPlay) {
        return 'testPlay';
      } else if (this.isVariationShow) {
        return 'checkVariation';
      } else {
        return 'checkKifu';
      }
    },
    user() {
      return this.$store.state.user;
    },
    userColor() {
      return this.kifu.userColor;
    },
    gameMode() {
      return this.$route.params.gameMode || this.kifu.gameMode;
    },
    kifuData() {
      return {
        black: `${kifuInfo.blackName(this.kifu) || ''} [${kifuInfo.blackRank(
          this.kifu
        )}]`,
        white: `${kifuInfo.whiteName(this.kifu) || ''} [${kifuInfo.whiteRank(
          this.kifu
        )}]`,
        result: filters.winningWayText(
          this.kifu.winningWay,
          this.kifu.komi,
          this.kifu.boardSize
        ),
        step: this.kifu.step,
        gameModeLabel:
          kifuInfo.verificationRank(this.kifu) +
          kifuInfo.KifuType[this.kifu.gameMode],
        date: this.$day(this.kifu.finishedTime).format('YYYY-MM-DD'),
      };
    },
    blackPlayer() {
      if (this.gameMode === 'pvp') {
        return {
          nickName: this.kifu.blackNickName,
          rank: aiLevelMapping.processAiLevel(this.kifu.blackRank),
          avatar: this.kifu.blackAvatar,
        };
      } else {
        if (this.userColor === 'black') {
          return this.user.userData;
        } else {
          // 防止prettier自動換行產生空白
          // prettier-ignore
          return {
            nickName: this.kifu.opponentName,
            rank: aiLevelMapping.processAiLevel(this.kifu.aiLevel),
            avatar:
              this.kifu.opponentName !== '黑小嘉'
                ? `avatar_tsumego_ai_${aiCharacterMapping[this.kifu.opponentName]}`
                : 'ai_b',
          };
        }
      }
    },
    whitePlayer() {
      if (this.gameMode === 'pvp') {
        return {
          nickName: this.kifu.whiteNickName,
          rank: aiLevelMapping.processAiLevel(this.kifu.whiteRank),
          avatar: this.kifu.whiteAvatar,
        };
      } else {
        if (this.userColor === 'white') {
          return this.user.userData;
        } else {
          // 防止prettier自動換行產生空白
          // prettier-ignore
          return {
            nickName: this.kifu.opponentName,
            rank: aiLevelMapping.processAiLevel(this.kifu.aiLevel),
            avatar:
              this.kifu.opponentName !== '白小嘉'
                ? `avatar_tsumego_ai_${aiCharacterMapping[this.kifu.opponentName]}`
                : 'ai_w',
          };
        }
      }
    },
    whitePlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.whitePlayer.avatar}.png?srcset`);
    },
    blackPlayerAvatar() {
      return require(`@/assets/img/personal/avatar/${this.blackPlayer.avatar}.png?srcset`);
    },
    isReviewGameLoading() {
      return this.$store.getters['env/isReviewGameLoading'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
  },
  watch: {
    async currentStep(newValue, oldValue) {
      if (newValue !== oldValue && !this.isTestPlay) {
        this.resetState();
        this.hasQuestionHand = this.getQuestionHand();
        this.hasExcellentHand = this.getExcellentHand();

        if (this.hasQuestionHand) {
          const questionHandPathMove =
            this.getReviewHandPathMove('questionHand');
          this.$refs.chessboard.showReviewHand(
            questionHandPathMove,
            'question'
          );
        }

        if (this.hasExcellentHand) {
          const excellentHandPathMove =
            this.getReviewHandPathMove('excellentHand');
          this.$refs.chessboard.showReviewHand(
            excellentHandPathMove,
            'excellent'
          );
        }
      }
    },
  },
  async created() {
    if (
      (!this.kifu && this.createdTime) ||
      (this.gameMode !== 'pvp' &&
        this.kifu.createdTime !== parseInt(this.createdTime)) ||
      (this.gameMode === 'pvp' && this.kifu.id !== this.createdTime)
    ) {
      await this.getKifu();
      if (this.isGameOverReivewed || this.kifu.isReviewed) {
        await this.reviewGame();
      }
    } else if (!this.kifu && !this.createdTime) {
      if (this.$device.isMobile) {
        this.$router.push({name: 'personal', query: {tab: 'kifu'}});
      } else {
        this.$router.push({name: 'kifus'});
      }
      BackEvent.addEvent(() => {
        this.$router.push({name: 'personal'});
        BackEvent.clearEvents();
      });
    }
    if (
      this.kifuReviewGame?.excellentHand?.length === 0 &&
      this.kifuReviewGame?.questionHand?.length === 0
    ) {
      this.$Message.success(this.$t('這盤棋沒有問題手喔！'));
    }
  },
  methods: {
    resetState() {
      this.questionComment = '';
      this.excellentComment = '';
      this.hasQuestionHand = false;
      this.hasExcellentHand = false;

      if (this.kifu.boardSize !== 19 && this.isSuggestHandShow) {
        this.suggestHandHide();
        this.suggestComment = '';
      }
    },
    infoShow() {
      BackEvent.addEvent(() => {
        this.closeInfo();
      });
      this.isInfoShow = true;
    },
    closeInfo() {
      BackEvent.popEvent();
      this.isInfoShow = false;
    },
    getQuestionHand() {
      if (this.kifuReviewGame && !this.isVariationShow) {
        const res = this.kifuReviewGame.questionHand.find((item) => {
          const _questionComment = reviewGameComment.processQuestionHand(
            item.handType
          );
          this.questionComment = _questionComment || '這手棋是問題手';
          const _suggestComment =
            item.suggestionType === 'joseki' ||
            item.suggestionType === 'miss joseki'
              ? item.reviewComment
              : reviewGameComment.processSuggest(item.suggestionType);
          // 防止prettier自動換行產生空白
          // prettier-ignore
          const suggestionStrategyComment = item.suggestionStrategyType
            ? reviewGameComment.processSuggestionStrategy(item.suggestionStrategyType)
            : '';
          this.suggestComment =
            (_suggestComment || '下在這裡會更厲害喔！') +
            suggestionStrategyComment;
          return item.stepNum === this.currentStep;
        });
        return !!res;
      } else {
        return false;
      }
    },
    getExcellentHand() {
      if (this.kifuReviewGame && !this.isVariationShow) {
        const res = this.kifuReviewGame.excellentHand.find((item) => {
          this.excellentComment =
            item.handType === 'joseki'
              ? item.reviewComment
              : reviewGameComment.processExcellentHand(item.handType);
          return item.stepNum === this.currentStep;
        });
        return !!res;
      } else {
        return false;
      }
    },
    async suggestHandShow() {
      if (this.isReviewGameLoading) return;
      this.isSuggestHandShow = true;
      if (this.kifu.boardSize >= 19) {
        const reviewHand = this.kifuReviewGame['questionHand'].find((item) => {
          return item.stepNum === this.currentStep;
        });
        if (reviewHand.pathMove.length > 1) {
          this.variation = reviewHand.pathMove;
        } else {
          this.ReviewGameLoadingContent = '變化圖運算中';
          const result = await this.getVariation(reviewHand.pathMove[0]);
          this.setReviewHandPathMove('questionHand', result.pathMove);
          this.variation = result.pathMove;
        }
        this.$store.commit('env/setIsReviewGameLoading', false);
        BackEvent.addEvent(() => {
          this.suggestHandHide();
        });
      } else {
        const questionHandPathMove = this.getReviewHandPathMove('questionHand');
        this.$refs.chessboard.showReviewHand(questionHandPathMove, 'suggest');
      }
    },
    async suggestHandHide() {
      this.isSuggestHandShow = false;
      if (this.kifu.boardSize >= 19) {
        this.closeVariation();
      } else {
        this.$refs.chessboard.hideReviewHand();
      }
    },
    onStepChange(step) {
      this.currentStep = step;
    },
    onCapCountChange(captureCount) {
      this.capCount = captureCount;
    },
    onBoardGridChange(boardGrid) {
      let blackBoardCount = 0;
      let whiteBoardCount = 0;
      const arr = _.flatten(boardGrid);
      arr.forEach((value) => {
        if (value === 1) {
          blackBoardCount += 1;
        } else if (value === -1) {
          whiteBoardCount += 1;
        }
      });
      this.boardCount.blackBoardCount = blackBoardCount;
      this.boardCount.whiteBoardCount = whiteBoardCount;
    },
    getReviewHandPathMove(handType) {
      const reviewHand = this.kifuReviewGame[handType].find((item) => {
        if (item.stepNum === this.currentStep) {
          return item.pathMove;
        }
      });
      return boardToNumber(reviewHand.pathMove[0], this.kifu.boardSize);
    },
    setReviewHandPathMove(handType, pathMove) {
      this.kifuReviewGame[handType].forEach((item) => {
        if (item.stepNum === this.currentStep && item.pathMove.length <= 1) {
          item.pathMove = pathMove;
        }
      });
    },
    async closeVariation() {
      BackEvent.popEvent();
      this.variation = null;
    },
    // about chessboardtoolbar
    onToolbarClick(event) {
      if (event === 'kifuInfo') {
        this.infoShow();
      } else {
        this.$refs.chessboard.onToolbarClick(event);
      }
    },
    goto(step) {
      this.$refs.chessboard.goto(step);
    },
    onStepTypeChange(stepType) {
      this.stepType = stepType;
    },
    onMaxStepChange(step) {
      this.maxStep = step;
    },
    onTestPlayChange(value) {
      this.isTestPlay = value;
    },
    goSetting() {
      return this.$device.isMobile
        ? this.$router.push({name: 'roomSetting'})
        : (this.isSettingShow = true);
    },
    onBaseHeaderRightClick() {
      this.$device.isMobile ? this.infoShow() : this.goSetting();
    },
    onClickGoBack() {
      this.$store.commit('aiGame/resetKifuData');
      return this.$router.push({name: 'kifus'});
    },
    async getKifu() {
      if (this.isLoading) return;
      this.$store.commit('env/setIsLoading', true);
      try {
        await this.$store.dispatch('aiGame/getKifu', {
          createdTime: this.createdTime,
          gameMode: this.gameMode,
        });
      } catch {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },

    async reviewGame() {
      if (this.isReviewGameLoading) return;
      this.$store.commit('env/setIsReviewGameLoading', true);
      this.$refs.chessboard.isInfluence = false;
      this.$refs.chessboard.influenceController.removeInfluence();
      try {
        const result = await aiGameService.reviewGame({
          id: this.kifu.id,
          gameMode: this.kifu.gameMode,
          coursePlan: this.currentPlan.id,
        });
        this.$store.commit('aiGame/setKifuReviewGame', result);
        this.$store.commit('aiGame/setKifuIsReviewed');
        this.isReviewed = true;
      } catch {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsReviewGameLoading', false);
        this.$Message.success(this.$t('已覆盤'));
      }
    },
    async getVariation(aiMove) {
      if (this.isReviewGameLoading) return;
      this.$store.commit('env/setIsReviewGameLoading', true);
      try {
        const currenStepSgf = getSgfOnStep(this.kifu.sgf, this.currentStep - 1);
        const sgf = appendSgf(currenStepSgf, aiMove, this.kifu.boardSize);
        const result = await aiGameService.variation(
          sgf,
          this.kifu.id,
          this.currentStep
        );
        result.pathMove = [aiMove, ...result.pathMove];
        return result;
      } catch (error) {
        console.log(error);
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsReviewGameLoading', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.kifu-preview {
  .content {
    height: calc(100% - 56px);
    .info {
      box-shadow: 0 2px 7px 0 rgba(199, 199, 199, 0.3);
      padding: 12px;
      border-radius: 10px;
    }
    .name {
      width: 73px;
    }
    .rank {
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
    }
    .black .rank {
      background: $black-chess;
    }
    .white .rank {
      border: solid 1px $line-2;
    }
  }
  .kifu-info {
    top: 0;
    z-index: 9999;
    position: absolute;
    @media screen and (min-width: 768px) {
      position: unset;
    }
  }
  .wrap-operate-panel {
    width: 100%;
    @media screen and (min-width: 768px) {
      min-width: 289px;
      width: 289px;
      margin: 0;
      height: 100%;
      border-radius: $rounded-md;
      padding: 16px;
    }
    @media screen and (min-width: 1440px) {
      min-width: 446px;
      width: 446px;
    }
    .operate-panel {
      @media screen and (min-width: 768px) {
        background: $bgsection;
        height: 100%;
        border-radius: 10px;
      }
      .wrap-victory-condition {
        @media screen and (min-width: 768px) {
          padding: 5px 0;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
      }
    }
  }
  .wrap-user {
    width: 100%;
    margin: 0;
    .avatar {
      width: 40px;
      height: 40px;
      margin: 8px;
      @media screen and (min-width: 768px) {
        width: 80px;
        height: 80px;
        margin: 10px 16px;
      }
    }
    .wrap-user-details {
      max-width: calc(100% - 40px - 24px);
      .user-name {
        font-size: 16px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
      .user-detail {
        font-size: 14px;
        @media screen and (min-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }
  // SLIDE_TRANSITION
  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.3s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  }
  .slide-left-leave-active,
  .slide-right-enter {
    transform: translate(100vw, 0);
  }
  .slide-left-leave-to {
    animation: none;
  }
  .remark-wrapper {
    width: 100%;
    position: relative;
    @media screen and (min-width: 768px) {
      height: auto;
      margin-bottom: 14px;
    }
    .remark {
      width: 100%;
      background: white;
      border-radius: 10px;
      color: $font-normal;
      font-size: 16px;
      line-height: 28px;
      @media screen and (min-width: 768px) {
        height: auto;
        border-radius: 6px;
        position: absolute;
        box-shadow: 0px 2px 7px 0px #c7c7c74d;
        margin-bottom: 20px;
        bottom: -14px;
        width: calc(100% - 16px);
        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 12px 7px 0 7px;
          border-color: #ffffff transparent transparent transparent;
        }
        .fade-enter-active,
        .fade-leave-active {
          transition: opacity 0.5s;
        }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
          opacity: 0;
        }
      }
      .icon-like,
      .icon-X {
        font-size: 24px;
      }
      .question-comment {
        margin-bottom: 20px;
      }
      .check-question-hand {
        padding: 6px 8px;
        // width: 120px;
        white-space: nowrap;
        // @media screen and (min-width: 768px) {
        //   text-decoration: unset;
        // }
      }
    }
  }
}
</style>
